<template>
  <div>
    <h1>{{ title }}</h1>

    <div class="md:my-6 md:flex md:justify-between lg:my-12">
      <div class="my-6 flex md:my-0">
        <a
          @click="changeDateRange('all-time')"
          class="rounded-md px-5 py-3"
          :class="{ 'bg-gray-100 text-paragraph': dateRange === 'all-time' }"
          >All Time</a
        >
        <a
          @click="changeDateRange('custom')"
          class="rounded-md px-5 py-3"
          :class="{ 'bg-gray-100 text-paragraph': dateRange === 'custom' }"
          >Custom</a
        >
      </div>

      <div class="my-6 flex md:my-0">
        <Button as="a" :href="advisorsSignedUrl" type="primary" prepend-icon="download">Download Advisors CSV</Button>
      </div>
    </div>

    <div v-show="dateRange === 'custom'" class="filters">
      <InputGroup label="Start Date">
        <DatePicker v-model="filters.start_date" :presets="false" :append-icon="false" @input="onStartDateChange" />
      </InputGroup>
      <InputGroup label="End Date">
        <DatePicker v-model="filters.end_date" :presets="false" :append-icon="false" @input="onEndDateChange" />
      </InputGroup>
    </div>

    <div class="grid gap-6 lg:grid-cols-2 lg:gap-12">
      <div>
        <DataTable
          title="Top Performing Collections"
          endpoint="analytics/trade/top-performing-collections"
          :perPage="13"
          :params="filters"
          :sort="{ consumer_views: 'desc' }"
        >
          <template v-slot:buttons>
            <Button as="a" :href="topPerformingCollectionsSignedUrl" type="primary" prepend-icon="download"
              >Download CSV</Button
            >
          </template>
          <template v-slot:rows="rows">
            <DataTableRow v-for="(row, i) in rows" :key="i" :row="row">
              <DataTableColumn title="Collection" sortable="name">
                <router-link :to="{ name: 'inspires-collection-analytics', params: { id: row.id } }">{{
                  row.name
                }}</router-link>
              </DataTableColumn>
              <DataTableColumn title="Acceptances" sortable="acceptances" width="max">
                {{ row.acceptances }}
              </DataTableColumn>
              <DataTableColumn title="Views" sortable="consumer_views" width="max">
                {{ row.consumer_views }}
              </DataTableColumn>
            </DataTableRow>
          </template>
        </DataTable>
      </div>

      <div>
        <DataTable
          title="Top Performing Advisors"
          endpoint="analytics/trade/top-performing-advisors"
          :perPage="10"
          :params="filters"
          :sort="{ consumer_views: 'desc' }"
        >
          <template v-slot:buttons>
            <Button as="a" :href="topPerformingAdvisorsSignedUrl" type="primary" prepend-icon="download"
              >Download CSV</Button
            >
          </template>
          <template v-slot:rows="rows">
            <DataTableRow v-for="(row, i) in rows" :key="i" :row="row">
              <DataTableColumn title="Advisor" sortable="first_name">
                <router-link
                  :to="{ name: 'inspires-user-analytics', params: { id: row.id } }"
                  class="flex items-center space-x-4"
                  :class="{ 'text-supplement': !row.full_name }"
                >
                  <img v-if="row.avatar" :src="row.avatar.thumb_url" class="h-10 w-10 rounded-full" />
                  <span
                    v-else
                    class="grid h-10 w-10 place-items-center rounded-full bg-gray-200 text-xs font-medium text-supplement"
                    >{{ row.initials || "?" }}</span
                  >
                  <span>{{ row.full_name || "-" }}</span>
                </router-link>
              </DataTableColumn>
              <DataTableColumn title="Acceptances" sortable="acceptances" width="max">
                {{ row.acceptances }}
              </DataTableColumn>
              <DataTableColumn title="Views" sortable="consumer_views" width="max">
                {{ row.consumer_views }}
              </DataTableColumn>
            </DataTableRow>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { sub as dateSub } from "date-fns"
import { isEmpty } from "lodash-es"

function formatDate(date) {
  let month = date.getMonth() + 1
  let day = date.getDate()

  return [date.getFullYear(), (month > 9 ? "" : "0") + month, (day > 9 ? "" : "0") + day].join("-")
}

export default {
  watch: {
    $route: "setup"
  },
  metaInfo() {
    return { title: this.title, bodyAttrs: { class: "page-trade-analytics" } }
  },
  data: () => ({
    title: "Trade Analytics",
    filters: {
      start_date: "2022-10-01",
      end_date: formatDate(dateSub(new Date(), { days: 1 })),
      collection_id: null,
      folder_id: null
    },
    dateRange: "all-time",
    advisorsSignedUrl: null,
    topPerformingAdvisorsSignedUrl: null,
    topPerformingCollectionsSignedUrl: null
  }),
  beforeMount() {
    this.setup()
  },
  async created() {
    const [advisorsResponse, topPerformingAdvisorsResponse, topPerformingCollectionsResponse] = await Promise.all([
      this.$api.get("advisors/signed-url"),
      this.$api.get("analytics/trade/top-performing-advisors/signed-url"),
      this.$api.get("analytics/trade/top-performing-collections/signed-url")
    ])

    this.advisorsSignedUrl = advisorsResponse.data.url
    this.topPerformingAdvisorsSignedUrl = topPerformingAdvisorsResponse.data.url
    this.topPerformingCollectionsSignedUrl = topPerformingCollectionsResponse.data.url
  },
  methods: {
    async setup() {
      let { start_date, end_date } = this.$route.query
      let query = {}

      if (start_date) {
        this.filters.start_date = start_date
      } else {
        query.start_date = this.filters.start_date
      }

      if (end_date) {
        this.filters.end_date = end_date
      } else {
        query.end_date = this.filters.end_date
      }

      this.dateRange = this.currentDateRange()

      if (!isEmpty(query)) {
        this.updateUri(query)
      }
    },
    currentDateRange() {
      return this.filters.start_date === "2022-10-01" &&
        this.filters.end_date === formatDate(dateSub(new Date(), { days: 1 }))
        ? "all-time"
        : "custom"
    },
    onStartDateChange(date) {
      this.dateRange = this.currentDateRange()
      this.updateUri({ start_date: date })
    },
    onEndDateChange(date) {
      this.dateRange = this.currentDateRange()
      this.updateUri({ end_date: date })
    },
    updateUri(query) {
      this.$router.replace({
        name: "trade-analytics",
        query: Object.assign({}, this.$route.query, query)
      })
    },
    changeDateRange(range) {
      if (range === this.dateRange) {
        return false
      }

      const start_date = range === "all-time" ? "2022-10-01" : formatDate(dateSub(new Date(), { months: 1, days: 1 }))
      const end_date = formatDate(dateSub(new Date(), { days: 1 }))

      this.dateRange = range
      this.filters.start_date = start_date
      this.filters.end_date = end_date

      this.updateUri({ start_date, end_date })
    }
  }
}
</script>

<style>
.page-trade-analytics {
  .section-title {
    @apply my-6 border-b-2 pb-3;
    @apply md:my-8;
    @apply lg:mt-12;
  }

  .card + .section-title {
    @apply lg:mt-20;
  }

  .filters {
    @apply md:-mx-3 md:mb-6 md:flex;
    @apply lg:mb-12;

    .form-inputgroup {
      @apply md:mx-3 md:mb-0;
    }
  }

  .filters__filter-by .form-autocomplete-results .icon {
    @apply mr-3 text-supplement;
  }
}
</style>
